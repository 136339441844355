import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Residence from '../components/Shared/Residence'
import { head } from '../components/head'

export const Head = head('umbracoListingResidencePage', 'residence-by-city')

export default function SingleResidenceByCity(props: {
  data: any
  location: any
}) { 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <Residence {...props} byCity />
}

export const query = graphql`
  query ($city: String!, $lang: String!) {
    umbracoCity(name: { eq: $city }, lang: { eq: $lang }) {
      bottomSEOText
    }
    allUmbracoResidencePage(
      filter: { city: { name: { eq: $city } }, lang: { eq: $lang } }
    ) {
      nodes {
        id
        name
        _url
        _urls {
          fr
          en_us
        }
        city {
          name
        }
        amenities {
          name
          title
          contentTypeAlias
        }
        imageCover {
          src
          focalPointUrlTemplate
        }
        address
        shortName
        shortDescription
        title
        comingSoon
        tag {
          content {
            color
            title
            contentTypeAlias
          }
        }
        thumbnailImages {
          gatsbyImageData(width: 1560, quality: 90)
          media {
            alt
            name
            _url
          }
        }
        items {
          content {
            itemsAccomodation {
              accomodationType {
                name
                accomodationTypeTitle
              }
            }
            newItemsAccomodation {
              content {
                accomodationType {
                  name
                  accomodationTypeTitle
                }
              }
            }
          }
        }
      }
    }
    umbracoListingResidencePage(lang: { eq: $lang }) {
      contentTypeAlias
      name
      _url
      _urls {
        fr
        en_us
      }
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      bottomSEOText
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      blocksPush {
        content {
          itemsPush {
            name
            title
            backgroundColor
            title
            subtitle
            link {
              url
              type
              name
            }
            linkEvent
            pushStyle
          }
        }
      }
      eventsList {
        content {
          event {
            title
            subtitle
            bg
            thumbnail {
              media {
                alt
                _url
              }
              gatsbyImageData(width: 880, quality: 90)
            }
            backgroundVimeoId
            category {
              color
              title
            }
            price
          }
          eventPrice
        }
      }
    }
    umbracoInstagram(lang: { eq: $lang }) {
      title
      hashtag
      instagramHandle
      instagramLinkButton {
        url
        name
        type
      }
    }
    allUmbracoCity(filter: { lang: { eq: $lang } }) {
      nodes {
        name
        title
        _url
        metaTitle
        metaDescription
        metaFollow
        metaIndex
        twitterCard {
          id
          url
        }
        facebookCard {
          id
          url
        }
        linkedResidences {
          name
          title
          shortDescription
          shortName
          address
          _url
          _urls {
            fr
          }
          amenities {
            name
            title
            contentTypeAlias
          }
          imageCover {
            src
            focalPointUrlTemplate
          }
          tag {
            content {
              color
              contentTypeAlias
              title
            }
          }
          comingSoon
          thumbnailImages {
            gatsbyImageData(width: 780, quality: 90)
            media {
              alt
              name
              _url
            }
          }
          items {
            content {
              newItemsAccomodation {
                content {
                  accomodationType {
                    name
                    accomodationTypeTitle
                  }
                }
              }
            }
          }
        }
      }
    }
    allUmbracoAccomodationType {
      nodes {
        accomodationTypeTitle
      }
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
